<template>
  <div class="form">
    <form @submit.prevent="onSubmit" novalidate>
      <div class="email">
        <input 
          type="email" 
          v-model="email" 
          name="email" 
          id="email" 
          :placeholder="$t('client.text_84')"
          v-validate="'required|email'"
        >
        <b-form-invalid-feedback :state="!errors.has('email')">
          {{ $t('client.text_85') }}
        </b-form-invalid-feedback>
      </div>
      <div class="whatsapp">
        <input 
          type="password"
          v-model="password"
          name="password"
          id="password"
          :placeholder="$t('client.text_86')"
          v-validate="'required'"
        >
        <b-form-invalid-feedback :state="!errors.has('password')">
          {{ $t('client.text_87') }}
        </b-form-invalid-feedback>
        <b-form-invalid-feedback :state="success">
          {{ $t('client.text_88') }}
        </b-form-invalid-feedback>
      </div>
      <div class="next">
        <button
          ref="button"
          v-if="!loginClub"
          :disabled="loading"
          type="submit"
          @click="onSubmit"
          class="btn-next">{{ $t('client.text_89') }}
        </button>
        <button
          ref="button"
          v-else
          :disabled="loading"
          type="submit"
          @click="clubSubmit"
          class="btn-next">{{ $t('client.text_89') }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'LoginFormComponent',
  data() {
    return {
      email: "",
      password: "",
      success: true,
      loading: false,
      loginClub: false,
      urlClub: "",
    };
  },
  methods: {
    existClubParams() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const type = urlParams.get("type");
      const url = urlParams.get("redirectTo");
      if (type === undefined || type === "") {
        this.loginClub = false;
      } else if (type === "club") {
        this.loginClub = true;
        if (url !== undefined && url !== "") {
          this.urlClub = url;
        }
      }
    },
    clubSubmit() {
      this.submit = true;
      this.success = true;
      serviceClub
        .login(this.email, this.password)
        .then((resp) => {
          if (resp.success === true) {
            if (this.urlClub === "") {
              window.location.href = "https://voompplay.com.br/?ck=" + resp.string;
            } else {
              window.location.href =
                "http://" + this.urlClub + "?ck=" + resp.string;
            }
          } else {
            this.success = false;
            this.loading = false;
          }
        })
        .catch(() => {
          this.success = false;
          this.loading = false;
        });
    },
    onSubmit() {
      this.submit = true;
      this.success = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.loading = true;
          var that = this;

          let data = {
            username: this.email,
            password: this.password,
          };

          this.$store
            .dispatch("loginRequest", data)
            .then((response) => {
              this.$router.push("/dashboard");
            })
            .catch(function (err) {
              that.success = false;
              that.loading = false;
            });
        }
      });
    },
    mounted() {
    this.existClubParams();
  },
  }
}
</script>

<style lang="scss" scoped>
.invalid-feedback{
  width: 100% !important;
  font-size: 95% !important;
  color: #fd002c !important;
  font-weight: 600;
  transition: 1.5s;
}
</style>